import React from 'react';
import BagScore from "./BagScore";
import style from '../styles/Round.module.css';
import {Bag} from "../types/Bag";
import {getScore} from "../utils/scoreUtils";

export type RoundProps = {
    bags: Bag[];
    updateBags: (bagNumber: number, points: number) => void;
};

function Round({bags, updateBags} : RoundProps) {
    const totalScore = getScore(bags);

    return (
        <div className={style.round}>
            { bags.map(bag => <BagScore key={bag.number} bag={bag} onChange={updateBags} />) }
            Round Score: {totalScore}
        </div>
    );
}

export default Round;
