import React, {useState} from 'react';
import './App.css';
import Game from "./components/Game";
import useSimpleMode from "./hooks/useSimpleMode";

function App() {
    const [gameId, setGameId] = useState(0);
    const { simpleMode, toggleSimpleMode } = useSimpleMode();

    const newGame = () => {
        setGameId((prev) => prev + 1);
    };

  return (
    <div className="App">
      <header className="App-header">
        <h2>Cornhole</h2>
          <button onClick={toggleSimpleMode}>Switch to {simpleMode ? 'Bag Mode' : 'Simple Mode'}</button>
          <button onClick={newGame}>New Game</button>
      </header>
      <main>
          <Game id={gameId} simpleMode={simpleMode} />
      </main>
    </div>
  );
}

export default App;
