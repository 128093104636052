import {Bag} from "../types/Bag";
import BagButton from "./BagButton";

type BagScoreProps = {
    bag: Bag;
    onChange: (bag: number, points: number) => void;
};

const bagPoints = [0, 1, 3];

const BagScore = ({bag, onChange} : BagScoreProps) => {
    return (<div>
        {bagPoints.map(bagPoint => {
            const isChosen = bagPoint === bag.points;
            const handleClick = () => onChange(bag.number, bagPoint);
            return <BagButton points={bagPoint} key={bagPoint} isChosen={isChosen} onClick={handleClick}/>;
        })}
    </div>);
};

export default BagScore;