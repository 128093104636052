import {useCallback, useEffect, useState} from "react";

const simpleModeKey = "useSimpleMode";

const loadSimpleMode = () => {
    const savedMode = localStorage.getItem(simpleModeKey);

    if (savedMode) {
        const parsed = JSON.parse(savedMode);

        if (parsed === true) {
            return true;
        }
    }

    return false;
};

const useSimpleMode = () => {
    const [simpleMode, setSimpleMode] = useState(loadSimpleMode);

    useEffect(() => {
        localStorage.setItem(simpleModeKey, JSON.stringify(simpleMode));
    }, [simpleMode]);

    const toggleSimpleMode = useCallback(() => setSimpleMode((prev) => !prev), [setSimpleMode]);

    return { simpleMode, toggleSimpleMode };
};

export default useSimpleMode;