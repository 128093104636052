import React from 'react';
import style from '../styles/Round.module.css';
import BagButton from "./BagButton";

export type SimpleRoundProps = {
    updateScore: (bagNumber: number, points: number) => void;
    chosen: number | undefined;
};

const pointsRow = [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10, 11, 12]];

function Round({updateScore, chosen} : SimpleRoundProps) {

    return (
        <div className={style.round}>
            { pointsRow.map(row => (
                <div key={row[0]}>
                    {row.map(points => {
                        const isChosen = chosen === points;
                        const handleClick = () => updateScore(1, points);
                        return <BagButton points={points} key={points} isChosen={isChosen} onClick={handleClick}/>;
                    })}
                </div>
                )) }
        </div>
    );
}

export default Round;
