import styles from '../styles/ScoreDisplay.module.css';

type ScoreDisplayProps = {
    score: number;
};

const ScoreDisplay = ({score} : ScoreDisplayProps) => {
    return (
        <h1 className={styles.scoreDisplay}>{score}</h1>
    );
};

export default ScoreDisplay;