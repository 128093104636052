import '../styles/BagButtonScore.module..css';

type BagButtonProps = {
    points: number;
    onClick: () => void;
    isChosen: boolean;
};

const BagButton = ({points, onClick, isChosen} : BagButtonProps) => {
    let buttonClass = 'bagButton';

    if (isChosen) {
        buttonClass += ' chosenBagButton';
    }

    return <button className={buttonClass} onClick={onClick}>{points}</button>
};

export default BagButton;