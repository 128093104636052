import React, {useCallback, useEffect, useState} from 'react';
import styles from '../styles/Game.module.css';
import {Bag} from "../types/Bag";
import {getScore} from "../utils/scoreUtils";
import Round from "./Round";
import ScoreDisplay from "./ScoreDisplay";
import SimpleRound from "./SimpleRound";

type Player = {
    name: string;
};

export type Team = {
    name: string;
    players: Player[];
    rounds: RoundState[];
};

export type RoundState = {
    number: number;
    bags: Bag[];
};

type GameState = {
    teamOne: Team;
    teamTwo: Team;
};

const initialBags: Bag[] = [
    { number: 1 },
    { number: 2 },
    { number: 3 },
    { number: 4 }
];

const initialTeam: Team = {
    name: 'Team One',
    players: [{
        name: 'Player 1'
    }, {
        name: 'Player 2'
    }],
    rounds: []
};

const initialGameState: GameState = {
    teamOne: { ...initialTeam, name: 'Team One' },
    teamTwo: { ...initialTeam, name: 'Team Two' }
};

type GameProps = {
    id: number;
    simpleMode: boolean;
};

const Game = ({id, simpleMode} : GameProps) => {
    const [gameState, setGameState] = useState(initialGameState);
    const [teamOneBags, setTeamOneBags] = useState(initialBags);
    const [teamTwoBags, setTeamTwoBags] = useState(initialBags);
    const [teamOneScore, setTeamOneScore] = useState(0);
    const [teamTwoScore, setTeamTwoScore] = useState(0);

    useEffect(() => {
        setGameState(initialGameState);
        setTeamOneBags(initialBags);
        setTeamTwoBags(initialBags);
        setTeamOneScore(0);
        setTeamTwoScore(0);
    }, [id]);

    useEffect(() => {
        setTeamOneBags(initialBags);
        setTeamTwoBags(initialBags);
    }, [simpleMode]);

    const handleBagChanged = useCallback((bagNumber: number, points: number, setBags: React.Dispatch<React.SetStateAction<Bag[]>>) => {
        const updateBags = (bags: Bag[]) => {
            return bags.map(bag => {
                if (bag.number === bagNumber) {
                    return {...bag, points}
                }

                return bag;
            });
        };

        setBags(updateBags);

        if (simpleMode) {
            if (setBags === setTeamOneBags) {
                setTeamTwoBags(initialBags);
            } else {
                setTeamOneBags(initialBags);
            }
        }
    }, [simpleMode]);

    const handleTeamOneBagsChanged = useCallback((bagNumber: number, points: number) =>
        handleBagChanged(bagNumber, points, setTeamOneBags), [handleBagChanged]);

    const handleTeamTwoBagsChanged = useCallback((bagNumber: number, points: number) =>
        handleBagChanged(bagNumber, points, setTeamTwoBags), [handleBagChanged]);

    const isRoundComplete = (simpleMode && (teamOneBags[0].points || teamTwoBags[0].points)) ||
        [...teamOneBags, ...teamTwoBags].reduce((prev, current) => current.points !== undefined ? 1 + prev : prev, 0) === 8;

    const getRoundWinner = () => {
        const teamOneScore = getScore(teamOneBags);
        const teamTwoScore = getScore(teamTwoBags);

        if (teamOneScore === teamTwoScore) return null;

        return teamOneScore > teamTwoScore ? {
            team: gameState.teamOne,
            score: teamOneScore - teamTwoScore
        } : {
            team: gameState.teamTwo,
            score: teamTwoScore - teamOneScore
        };
    };

    const roundWinner = isRoundComplete ? getRoundWinner() : null;

    const handleSubmit = () => {
        if (roundWinner) {
            if (roundWinner.team === gameState.teamOne) setTeamOneScore((prev) => prev + roundWinner.score);
            if (roundWinner.team === gameState.teamTwo) setTeamTwoScore((prev) => prev + roundWinner.score);
        }

        setTeamOneBags(initialBags);
        setTeamTwoBags(initialBags);
    };

    let winner: Team | null = null;

    if ((teamOneScore >= 21 || teamTwoScore >= 21) && teamOneScore !== teamTwoScore)
    {
        winner = teamOneScore > teamTwoScore ? gameState.teamOne : gameState.teamTwo;
    }

    return (
        <div>
            <div className={styles.teamCards}>
                <div className={styles.teamCard}>
                    <h3 className="center">{gameState.teamOne.name}</h3>
                    <section>
                        {!winner && (simpleMode
                            ? <SimpleRound chosen={teamOneBags[0].points} updateScore={handleTeamOneBagsChanged} />
                            : <Round bags={teamOneBags} updateBags={handleTeamOneBagsChanged} /> )}
                    </section>
                    <ScoreDisplay score={teamOneScore} />
                </div>
                <div className={styles.teamCard}>
                    <h3 className="center">{gameState.teamTwo.name}</h3>
                    <section>
                        {!winner && (simpleMode
                            ? <SimpleRound chosen={teamTwoBags[0].points} updateScore={handleTeamTwoBagsChanged} />
                            : <Round bags={teamTwoBags} updateBags={handleTeamTwoBagsChanged} /> )}
                    </section>
                    <ScoreDisplay score={teamTwoScore} />
                </div>
            </div>
            { !winner && (
            <div className="center">
                <button className={styles.roundCompleteButton}
                        disabled={!isRoundComplete}
                        onClick={handleSubmit}>Next Round</button>
                { roundWinner && (
                    <p><strong>{roundWinner.team.name}</strong> +{roundWinner.score}</p>
                )}
            </div>)}
            { winner && <h2 className="center">{winner.name} wins!</h2> }
        </div>
    );
};

export default Game;